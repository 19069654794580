import React, { FC } from "react";
import { Text, Box } from "theme-ui";
import { Heading } from "../common";

const AtypicalHow: FC = () => {
  return (
    <Box>
      <Heading>Nos valeurs</Heading>
      <Text>
        La confiance, la transparence, le respect, la curiosité et l’audace sont les moteurs
        essentiels de notre relation aux clients et aux collaborateurs.
      </Text>
      <blockquote cite="Bill Gates">
        <Text>
          “Montrez aux gens les problèmes, puis montrez-leur les solutions : ils seront incités à
          agir.”
        </Text>
        <Text>-- Bill Gates</Text>
      </blockquote>
      <Heading as="h3">Nous innovons</Heading>
      <Text>
        Vous envisagez d’intégrer des nouvelles technologies dans vos processus, contactez nous. Nos
        équipes sont déjà actives dans des projets très novateurs. La découverte ne nous fait jamais
        peur, elle stimule notre envie d’aller plus loin.
      </Text>
      <Text>
        Et nos collaborateurs... ils ne se contentent pas d’appliquer ce qu’ils ont appris aux
        études. Ils sont en veille technologique constante et s’intéressent aux dernières
        innovations du marché (Blockchain, AI...), les analyse et les critiques. La formation
        constante fait partie intégrante de notre ADN. Nos consultants sont formés pour travailler
        dans un environnement agile.
      </Text>
      <Heading as="h3">La place du Remote Working</Heading>
      <Text>
        Le monde impose parfois des changements de circonstance inattendus, des adaptations de
        dernière minute auxquelles le remote working peut être une première partie de réponse.
      </Text>
      <ul>
        <li>Il fait partie de notre culture d’entreprise</li>
        <li>Il représente l’avantage d’un gain en temps de gestion, en espace bureau</li>
        <li>
          Il a aussi des incidences positives sur la gestion des distances, des déplacements avec
          des impacts positifs en termes d’écologie et de stress.
        </li>
      </ul>
    </Box>
  );
};

export default AtypicalHow;
export { AtypicalHow };
