import React, { FC } from "react";
import PropTypes, { InferProps } from "prop-types";
import { Box } from "theme-ui";

const footerPropTypes = {
  children: PropTypes.node.isRequired,
};

type FooterProps = InferProps<typeof footerPropTypes>;

const Footer: FC<FooterProps> = ({ children, ...props }) => {
  return (
    <Box
      {...props}
      as="footer"
      sx={{
        bg: "primary",
        p: [2, 3],
        bottom: 0,
        left: 0,
        right: 0,
      }}
    >
      <Box sx={{ mx: "auto", maxWidth: [null, "980px"] }}>{children}</Box>
    </Box>
  );
};

Footer.propTypes = footerPropTypes;

export default Footer;
export { Footer };
export type { FooterProps };
