/* eslint-disable react/prop-types */
/** @jsx jsx */
import { jsx } from "theme-ui";
import { FC } from "react";
import { NavLink, NavLinkProps, useRouteMatch } from "react-router-dom";

type MenuNavLinkProps = NavLinkProps;

const MenuNavLink: FC<NavLinkProps> = ({ to, ...props }) => {
  const match = useRouteMatch(to.toString());
  const isExact = match?.isExact || false;

  return (
    <NavLink
      to={to}
      {...props}
      sx={{
        fontSize: 4,
        my: 1,
        color: isExact ? "primary" : "inherit",
        textDecoration: "none",
        display: "inline-block",
        transition: "color 0.2s",
      }}
    />
  );
};

export default MenuNavLink;
export { MenuNavLink };
export type { MenuNavLinkProps };
