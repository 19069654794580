import React, { FC } from "react";
import { Text } from "theme-ui";
import { Heading } from "../common";

const AtypicalWhat: FC = () => {
  return (
    <div>
      <Heading>Par delà cette spécificité, qu’avons-nous à vous offrir ?</Heading>
      <Text>
        Nous accompagnons l’évolution de vos systèmes informatiques avec un focus sur le déploiement
        d’applications durables, maintenables et compréhensibles.
      </Text>

      <Heading as="h3">Nous développons des solutions sur mesure:</Heading>
      <ul>
        <li>
          <strong>Back-end : </strong>
          .NET / WebAPI / Microsoft Azure / Blockchain
        </li>
        <li>
          <strong>Front-end : </strong>
          TypeScript / Electron / React / React Native / PWA
        </li>
      </ul>

      <Heading as="h3">Nous conseillons à propos de:</Heading>
      <ul>
        <li>
          <strong>Cross-platform : </strong>
          Desktop / Mobile / Web
        </li>
        <li>
          <strong>Architecture : </strong>
          SOA / Hexagonale / Clean Code
        </li>
        <li>
          <strong>Méthodologies : </strong>
          TDD / BDD / DDD / OpenSource / Agile / Scrum
        </li>
      </ul>
    </div>
  );
};

export default AtypicalWhat;
export { AtypicalWhat };
