const images = {
  illustration: "illu.svg",
  logoImage: "Logo-image.svg",
  logoText: "Logo-texte.svg",
  logo: "Logo.svg",
  heroBackground: "photos/hero.jpg",
  contact: "photos/contact.jpg",
  ambiances: ["photos/workshop-1746275_1920.jpg"],
} as const;

export default images;
