import React, { FC } from "react";
import PropTypes, { InferProps } from "prop-types";
import { Heading as UIHeading, HeadingProps as UIHeadingProps } from "theme-ui";

const headingPropTypes = {
  children: PropTypes.string.isRequired,
};

type HeadingProps = UIHeadingProps & InferProps<typeof headingPropTypes>;

const Heading: FC<HeadingProps> = ({ children, ...props }) => {
  return (
    <UIHeading as="h1" {...props} sx={{ mt: 3, color: "primary" }}>
      {children}
    </UIHeading>
  );
};

Heading.propTypes = headingPropTypes;

export default Heading;
export { Heading };
export type { HeadingProps };
