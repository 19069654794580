import React, { FC } from "react";
import { Box, Heading } from "theme-ui";
import images from "../../images";

const AtypicalHero: FC = () => {
  return (
    <Box
      sx={{
        px: 4,
        py: 6,
        backgroundImage: `url(${images.heroBackground})`,
        backgroundSize: "cover",
        borderRadius: 2,
        bg: "gray",
      }}
    >
      <Heading
        sx={{
          fontSize: [5, 6],
          textAlign: "center",
          color: "white",
          textShadow: "2px 2px 4px #3399cc",
        }}
      >
        Des consultants curieux,
        <br />
        passionnés et atypiques !
      </Heading>
    </Box>
  );
};

export default AtypicalHero;
export { AtypicalHero };
