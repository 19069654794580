import React, { FC } from "react";
import PropTypes, { InferProps } from "prop-types";
import { Link } from "theme-ui";

const atypicalPhoneNumberPropTypes = {
  isInline: PropTypes.bool,
};

type AtypicalPhoneNumberProps = InferProps<typeof atypicalPhoneNumberPropTypes>;

const AtypicalPhoneNumber: FC<AtypicalPhoneNumberProps> = ({ isInline }) => {
  const phoneNumber = "+32 473 32 29 29";
  const label = isInline ? phoneNumber : `Tél: ${phoneNumber}`;

  return (
    <Link
      href="tel:+32473322929"
      sx={{ color: "white", fontWeight: "bold", textDecoration: "none" }}
    >
      {label}
    </Link>
  );
};

AtypicalPhoneNumber.propTypes = atypicalPhoneNumberPropTypes;
AtypicalPhoneNumber.defaultProps = { isInline: false };

export default AtypicalPhoneNumber;
export { AtypicalPhoneNumber };
export type { AtypicalPhoneNumberProps };
