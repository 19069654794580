import React, { FC, Suspense, lazy } from "react";
import { Route, Switch } from "react-router-dom";
import RouteEnum from "./constants/RouteEnum";
import { AtypicalHeader, AtypicalFooter } from "./components/business";

const HomePage = lazy(() => import("./views/HomePage"));
const NotFoundPage = lazy(() => import("./views/NotFoundPage"));
const JobsPage = lazy(() => import("./views/JobsPage"));
const ContactPage = lazy(() => import("./views/ContactPage"));

const App: FC = () => {
  return (
    <>
      <AtypicalHeader />
      <Suspense fallback={<div>Loading</div>}>
        <AppRoutes />
      </Suspense>
      <AtypicalFooter />
    </>
  );
};

const AppRoutes: FC = () => {
  return (
    <Switch>
      <Route exact strict path={RouteEnum.Home} component={HomePage} />
      <Route path={RouteEnum.Jobs} component={JobsPage} />
      <Route path={RouteEnum.Contact} component={ContactPage} />
      <Route path="*" component={NotFoundPage} />
    </Switch>
  );
};

export default App;
