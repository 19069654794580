import { Theme } from "theme-ui";

const theme: Theme = {
  breakpoints: ["40em", "52em", "64em"],
  colors: {
    text: "#111",
    textLight: "#eee",
    background: "#ffffff",
    primary: "#3399cc",
    secondary: "#ff9966",
    accent: "#66ccff",
    highlight: "#3366cc",
    muted: "#f2b264",
    backgroundAccent: "#f3f3fd",
    white: "#f3f3fd",
  },
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64],
  fonts: {
    body: '"Josefin Sans", sans-serif',
    heading: '"Exo 2", sans-serif',
    monospace: "Menlo, monospace",
  },
  fontWeights: {
    body: 400,
    heading: 400,
    bold: 700,
  },
  lineHeights: {
    body: 1.25,
    heading: 1.125,
  },
  letterSpacings: {
    body: "normal",
    caps: "0.2em",
  },
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  // variants
  text: {
    heading: {
      fontFamily: "heading",
      lineHeight: "heading",
      color: "primary",
      mb: 3,
    },
    body: {
      fontFamily: "body",
      lineHeight: "body",
    },
  },
  links: {
    nav: {
      px: 2,
      py: 1,
      textTransform: "uppercase",
      letterSpacing: "0.2em",
    },
  },
  styles: {
    root: {
      fontFamily: "body",
      fontWeight: "body",
      lineHeight: "body",
    },
  },
};

export default theme;
export { theme };
