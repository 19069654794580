import React, { FC } from "react";
import PropTypes, { InferProps } from "prop-types";
import { ThemeProvider } from "theme-ui";
import { BrowserRouter } from "react-router-dom";
import { useWebFontsProvider } from "./hooks";
import { theme } from "./theme";

const appProvidersPropTypes = {
  children: PropTypes.node.isRequired,
};

type AppProvidersProps = InferProps<typeof appProvidersPropTypes>;

const AppProviders: FC<AppProvidersProps> = ({ children }) => {
  useWebFontsProvider();

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>{children}</BrowserRouter>
    </ThemeProvider>
  );
};

AppProviders.propTypes = appProvidersPropTypes;

export default AppProviders;
export { AppProviders };
export type { AppProvidersProps };
