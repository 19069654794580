import React, { FC } from "react";
import PropTypes, { InferProps } from "prop-types";
import { Box, Flex } from "theme-ui";

const headerPropTypes = {
  children: PropTypes.node.isRequired,
};

type HeaderProps = InferProps<typeof headerPropTypes>;

const Header: FC = ({ children, ...props }) => {
  return (
    <Box {...props} as="header" sx={{ p: [2, 3] }}>
      <Flex
        sx={{
          mx: "auto",
          py: 3,
          maxWidth: [null, "980px"],
          alignItems: "flex-end",
          justifyContent: "space-between",
          borderWidth: "2px",
          borderStyle: "solid none",
          borderColor: "primary",
        }}
      >
        {children}
      </Flex>
    </Box>
  );
};

Header.propTypes = headerPropTypes;

export default Header;
export { Header };
export type { HeaderProps };
