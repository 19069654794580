import WebFontLoader from "webfontloader";
import { useEffect } from "react";
import { log } from "../../helpers/log";

const useWebFontsProvider = (): void => {
  useEffect(() => {
    log("[Atyp] Initialize WebFonts");

    WebFontLoader.load({
      google: {
        families: ["Exo 2:400,700:latin-ext", "Josefin Sans:400,500,700:latin-ext"],
      },
    });
  }, []);
};

export { useWebFontsProvider };
