import React, { FC } from "react";
import PropTypes, { InferProps } from "prop-types";
import { Box, BoxProps, Flex } from "theme-ui";

const pagePropTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

type PageProps = BoxProps & InferProps<typeof pagePropTypes>;

const Page: FC<PageProps> = ({ children, ...props }) => {
  return (
    <Box {...props} sx={{ p: [2, 3], mb: 4 }}>
      <Flex
        sx={{
          mx: "auto",
          flexDirection: "column",
          maxWidth: [null, "980px"],
        }}
      >
        {children}
      </Flex>
    </Box>
  );
};

Page.propTypes = pagePropTypes;

export default Page;
export { Page };
export type { PageProps };
