import React, { FC } from "react";
import { Text } from "theme-ui";
import { Heading } from "../common";

const AtypicalWho: FC = () => {
  return (
    <div>
      <Heading>Qui sommes-nous ?</Heading>
      <Text>
        Nouvel acteur sur le marché, Atypical Consulting est une société de consultance en
        informatique créée par un Asperger avec des aspirations spécifiques :
      </Text>
      <ul>
        <li>identifier des collaborateurs potentiellement différents</li>
        <li>qui ont fait de l’informatique une passion plus qu’un simple job</li>
        <li>encadrés pour être motivés et efficaces</li>
        <li>stimulés pour penser out of the box</li>
      </ul>
      <Text>
        Nous souhaitons concentrer nos efforts sur l’intégration de profils potentiellement
        atypiques dans un environnement de travail classique et nous sommes convaincus que ces
        profils hors-normes, correctement encadrés, pourront être les architectes de vos plus belles
        réalisations.
      </Text>
    </div>
  );
};

export default AtypicalWho;
export { AtypicalWho };
