import React, { FC } from "react";
import { Divider, Text, Flex, Box, Heading } from "theme-ui";
import { Footer } from "../common";
import { AtypicalPhoneNumber } from ".";

const AtypicalFooter: FC = () => {
  return (
    <Footer>
      <Flex>
        <Box>
          <Heading sx={{ mt: 0, color: "white" }}>
            Atypical
            <br />
            Consulting
          </Heading>
        </Box>
        <Box sx={{ ml: 4, flex: 1 }}>
          <Text color="textLight">
            Vous pouvez nous contacter par téléphone au&nbsp;
            <AtypicalPhoneNumber isInline />
          </Text>
          <Divider sx={{ color: "white" }} />
          <Text color="textLight" sx={{ fontSize: 1 }}>
            2020 - Atypical Consulting SRL. Tous droits réservés.
            <br />
            TVA: BE 0744.517.956
            {/* <br />
            Engagement de confidentialité | Plan du site */}
          </Text>
        </Box>
      </Flex>
    </Footer>
  );
};

export default AtypicalFooter;
export { AtypicalFooter };
