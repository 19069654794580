import React, { FC } from "react";
import { Image, Text, Flex } from "theme-ui";
import { Header, MenuNavLink } from "../common";
import images from "../../images";
import RouteEnum from "../../constants/RouteEnum";

const AtypicalHeader: FC = () => {
  return (
    <Header>
      <MenuNavLink to={RouteEnum.Home}>
        <Image src={images.logo} sx={{ maxHeight: "140px" }} />
      </MenuNavLink>
      <Text color="text" sx={{ margin: 3 }}>
        <Flex as="nav" sx={{ flexDirection: "column" }}>
          <MenuNavLink to={RouteEnum.Home}> Services</MenuNavLink>
          <MenuNavLink to={RouteEnum.Jobs}>Jobs</MenuNavLink>
          <MenuNavLink to={RouteEnum.Contact}>Contact</MenuNavLink>
        </Flex>
      </Text>
    </Header>
  );
};

export default AtypicalHeader;
export { AtypicalHeader };
